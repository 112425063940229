import { TableContext } from "../context";
import React, { useState, useContext, useEffect } from "react";
import "../App.css";
import Papa from "papaparse";
import { IoMdDownload } from "react-icons/io";
import { saveAs } from "file-saver";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { DateTime } from 'luxon';

/**
 * Table component for displaying and filtering table data.
 * Provides options to filter data by risk, state, type, and category.
 * Allows downloading the filtered data as a CSV file.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.Header1 - The first header for the table.
 * @param {string} props.Header2 - The second header for the table.
 * @param {string} props.Header3 - The third header for the table.
 * @param {string} props.Header4 - The fourth header for the table.
 * @param {string} props.Header5 - The fifth header for the table.
 * @param {string} props.Header6 - The sixth header for the table.
 * @param {string} props.Header7 - The seventh header for the table.
 * @param {string} props.Header8 - The eighth header for the table.
 * @param {string} props.Header9 - The ninth header for the table.
 * @param {string} props.Header10 - The tenth header for the table.
 * @param {string} props.Header11 - The eleventh header for the table.
 */
export default function Table(props) {
    const { tableData, selectedTime } = useContext(TableContext);
    const [selectedRisk, setSelectedRisk] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [selectedRelation, setSelectedRelation] = useState("");
    const [selectedCategorization, setSelectedCategorization] = useState("");
    const [headingText, setHeadingText] = useState('Ongoing Changes');
    const [sortConfig, setSortConfig] = useState({ key: "Planned Start Date", direction: 'descending' });
    const [selectedTargetApplication, setSelectedTargetApplication] = useState("");

    const handleTargetApplicationChange = (e) => setSelectedTargetApplication(e.target.value);
    const handleRiskChange = (e) => setSelectedRisk(e.target.value);
    const handleStateChange = (e) => setSelectedState(e.target.value);
    const handleTypeChange = (e) => setSelectedType(e.target.value);
    const handleCategorizationChange = (e) => setSelectedCategorization(e.target.value);
    const handleRelationChange = (e) => setSelectedRelation(e.target.value);

    const filteredData = tableData?.filter((item) => {    
        return (
            (selectedRisk ? item["Risk"] === selectedRisk : true) &&
            (selectedState ? item["State"] === selectedState : true) &&
            (selectedType ? item["Type"] === selectedType : true) &&
            (selectedCategorization ? item["Category"] === selectedCategorization : true) &&
            (selectedRelation ? item["Relation"].includes(selectedRelation) : true) &&
            (selectedTargetApplication ? item["Relation"] && item["Relation"].includes(`to ${selectedTargetApplication}`) : true)
        );
    });

    const calculateDifferenceInHours = (startDate, endDate) => {
        const start = DateTime.fromFormat(startDate, 'yyyy-MM-dd HH:mm:ss');
        const end = DateTime.fromFormat(endDate, 'yyyy-MM-dd HH:mm:ss');
        return end.diff(start, 'hours').hours;
    };

    const getUniqueValues = (key) => {
        if (key === "Risk" && selectedRisk) {
            return [...new Set(tableData.map(item => item[key]))];
        }
        if (key === "State" && selectedState) {
            return [...new Set(tableData.map(item => item[key]))];
        }
        if (key === "Type" && selectedType) {
            return [...new Set(tableData.map(item => item[key]))];
        }
        if (key === "Category" && selectedCategorization) {
            return [...new Set(tableData.map(item => item[key]))];
        }
        if (key === "Relation") {
            const uniqueValues = new Set();
            filteredData.forEach(item => {
                if (item[key]) {
                    const values = item[key].split(',');
                    values.forEach(value => {
                        if (value.includes("Target")) {
                            uniqueValues.add("Target");
                        }
                        if (value.includes("Upstream")) {
                            uniqueValues.add("Upstream");
                        }
                        if (value.includes("Downstream")) {
                            uniqueValues.add("Downstream");
                        }
                    });
                }
            });
            return [...uniqueValues].sort();
        }
        if (key === "Target Application") {
            const uniqueApplications = new Set();
            filteredData.forEach(item => {
                if (item["Relation"]) {
                    const matches = item["Relation"].match(/to\s([^,]+)/g);
                    if (matches) {
                        matches.forEach(match => {
                            const application = match.split('to ')[1].trim();
                            uniqueApplications.add(application);
                        });
                    }
                }
            });
            return [...uniqueApplications].sort();
        }
        return [...new Set(filteredData.map(item => item[key]))];
    };


    useEffect(() => {
        const now = DateTime.now();
        const start = DateTime.fromJSDate(selectedTime.start);
        const end = DateTime.fromJSDate(selectedTime.end);

        const isPast = start.year < now.year ||
                       (start.year === now.year && start.month < now.month) ||
                       (start.year === now.year && start.month === now.month && start.day < now.day) ||
                       (start.year === now.year && start.month === now.month && start.day === now.day && start.hour < now.hour) ||
                       (start.year === now.year && start.month === now.month && start.day === now.day && start.hour === now.hour && start.minute < now.minute);

        const isFuture = end.year > now.year ||
                         (end.year === now.year && end.month > now.month) ||
                         (end.year === now.year && end.month === now.month && end.day > now.day) ||
                         (end.year === now.year && end.month === now.month && end.day === now.day && end.hour > now.hour) ||
                         (end.year === now.year && end.month === now.month && end.day === now.day && end.hour === now.hour && end.minute > now.minute);

        if (isPast && !isFuture) {
            setHeadingText("Past Changes");
        } else if (isFuture && !isPast) {
            setHeadingText("Future Changes");
        } else {
            setHeadingText("Ongoing Changes");
        }
    }, [selectedTime]);

    const getCloseCodeColor = (closeCode) => {
        switch (closeCode) {
            case 'successful':
                return '#00786b';
            case 'Success':
                return '#00786b';
            case 'unsuccessful':
                return '#e75858';
            case 'Failure':
                return '#e75858';
            case 'successful_issues':
                return '#d8d52c';
            default:
                return 'black';
        }
    };

    const handleDownloadClick = () => {
        const headers = [
            props.Header1,
            props.Header2,
            props.Header3,
            props.Header4,
            props.Header5,
            props.Header6,
            props.Header7,
            props.Header8,
            props.Header9,
            props.Header10,
            props.Header11,
        ];
        const csv = Papa.unparse({
          fields: headers,
          data: filteredData,
        });
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, "Change Management.csv");
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = React.useMemo(() => {
        if (sortConfig.key) {
            return [...filteredData].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return filteredData;
    }, [filteredData, sortConfig]);

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) {
            return <FaSort />;
        }
        if (sortConfig.direction === 'ascending') {
            return <FaSortUp />;
        }
        return <FaSortDown />;
    };

    return (
        <div className="table-container">
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: "auto", width: "97%"}}>
                <h2 style={{ fontWeight: "600", paddingLeft: '0.7vw'}}>{headingText}</h2>
                <button
                    style={{
                        border: "none",
                        borderRadius: "5px",
                        padding: "6px",
                        backgroundColor: "rgb(66, 176, 213)",
                        color: "white",
                        marginBottom: "0.4rem",
                        fontSize: "1rem",
                    }}
                    onClick={handleDownloadClick}
                    >
                    Extract 
                    <IoMdDownload
                        size={15}
                        style={{
                        marginLeft: "5px",
                        
                        }}
                    />
                </button>
            </div>
            <div style={{ margin: "auto", width: "97%", maxWidth: "fit-content", display: "flex", flexDirection: "row", justifyContent: "flex-end", fontSize: "0.85rem", boxShadow: "0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)", height: '100%'}}>
                <div style={{ width: "9vw", padding: "0.625rem", backgroundColor: "#87CEEB", borderRadius: "0.5rem", borderTopRightRadius: "0rem", borderBottomRightRadius: "0rem", overflowY: 'auto' }}>
                    <div style={{ padding: "0.625rem", backgroundColor: "#f0f0f0", borderRadius: "0.5rem", marginBottom: "0.625rem", textAlign: "center" }}>
                        <label style={{ fontSize: "0.8rem", fontWeight: "600" }}>Total Records: {sortedData.length}</label>
                    </div>
                    <div style={{ borderRadius: "0.5rem", marginBottom: "0.625rem", padding: "0.625rem", backgroundColor: "#f9f9f9" }}>
                        <label style={{ fontSize: "0.9rem", fontWeight: "600" }}>Risk:</label>
                        <div style={{ display: "flex", flexDirection: "column", margin: "0.3rem 0rem", paddingTop: "0.2rem" }}>
                            <label style={{ margin: "0.1rem 0rem" }}>
                                <input
                                    type="radio"
                                    value=""
                                    checked={selectedRisk === ""}
                                    onChange={handleRiskChange}
                                    style={{ marginRight: "0.2rem" }}
                                />
                                All
                            </label>
                            {getUniqueValues("Risk").map((risk, index) => (
                                <label key={index} style={{ margin: "0.1rem 0rem" }}>
                                    <input
                                        type="radio"
                                        value={risk}
                                        checked={selectedRisk === risk}
                                        onChange={handleRiskChange}
                                        style={{ marginRight: "0.2rem" }}
                                    />
                                    {risk}
                                </label>
                            ))}
                        </div>
                    </div>
                    {sortedData.some(value => value["Relation"]) && (
                        <div style={{ borderRadius: "0.5rem", marginBottom: "0.625rem", padding: "0.625rem", backgroundColor: "#f9f9f9" }}>
                            <label style={{ fontSize: "0.9rem", fontWeight: "600" }}>Relation:</label>
                            <div style={{ display: "flex", flexDirection: "column", margin: "0.3rem 0rem", paddingTop: "0.2rem" }}>
                                <label style={{ margin: "0.1rem 0rem" }}>
                                    <input
                                        type="radio"
                                        value=""
                                        checked={selectedRelation === ""}
                                        onChange={handleRelationChange}
                                        style={{ marginRight: "0.2rem" }}
                                    />
                                    All
                                </label>
                                {getUniqueValues("Relation").map((relation, index) => (
                                    <label key={index} style={{ margin: "0.1rem 0rem" }}>
                                        <input
                                            type="radio"
                                            value={relation}
                                            checked={selectedRelation === relation}
                                            onChange={handleRelationChange}
                                            style={{ marginRight: "0.2rem" }}
                                        />
                                        {relation}
                                    </label>
                                ))}
                            </div>
                        </div>
                    )}
                    {sortedData.some(value => value["Relation"]) && (
                        <div style={{ borderRadius: "0.5rem", marginBottom: "0.625rem", padding: "0.625rem", backgroundColor: "#f9f9f9" }}>
                            <label style={{ fontSize: "0.9rem", fontWeight: "600" }}>Target Application: </label>
                            <div style={{ display: "flex", flexDirection: "column", margin: "0.3rem 0rem", paddingTop: "0.2rem" }}>
                                <label style={{ margin: "0.1rem 0rem" }}>
                                    <input
                                        type="radio"
                                        value=""
                                        checked={selectedTargetApplication === ""}
                                        onChange={handleTargetApplicationChange}
                                        style={{ marginRight: "0.2rem" }}
                                    />
                                    All
                                </label>
                                {getUniqueValues("Target Application").map((application, index) => (
                                    <label key={index} style={{ margin: "0.1rem 0rem" }}>
                                        <input
                                            type="radio"
                                            value={application}
                                            checked={selectedTargetApplication === application}
                                            onChange={handleTargetApplicationChange}
                                            style={{ marginRight: "0.2rem" }}
                                        />
                                        {application}
                                    </label>
                                ))}
                            </div>
                        </div>
                    )}
                    <div style={{ borderRadius: "0.5rem", marginBottom: "0.625rem", padding: "0.625rem", backgroundColor: "#f9f9f9" }}>
                        <label style={{ fontSize: "0.9rem", fontWeight: "600" }}>State: </label>
                        <div style={{ display: "flex", flexDirection: "column", margin: "0.3rem 0rem", paddingTop: "0.2rem" }}>
                            <label style={{ margin: "0.1rem 0rem" }}>
                                <input
                                    type="radio"
                                    value=""
                                    checked={selectedState === ""}
                                    onChange={handleStateChange}
                                    style={{ marginRight: "0.2rem" }}
                                />
                                All
                            </label>
                            {getUniqueValues("State").map((state, index) => (
                                <label key={index} style={{ margin: "0.1rem 0rem" }}>
                                    <input
                                        type="radio"
                                        value={state}
                                        checked={selectedState === state}
                                        onChange={handleStateChange}
                                        style={{ marginRight: "0.2rem" }}
                                    />
                                    {state}
                                </label>
                            ))}
                        </div>
                    </div>
                    <div style={{ borderRadius: "0.5rem", marginBottom: "0.625rem", padding: "0.625rem", backgroundColor: "#f9f9f9" }}>
                        <label style={{ fontSize: "0.9rem", fontWeight: "600" }}>Type: </label>
                        <div style={{ display: "flex", flexDirection: "column", margin: "0.3rem 0rem", paddingTop: "0.2rem" }}>
                            <label style={{ margin: "0.1rem 0rem" }}>
                                <input
                                    type="radio"
                                    value=""
                                    checked={selectedType === ""}
                                    onChange={handleTypeChange}
                                    style={{ marginRight: "0.2rem" }}
                                />
                                All
                            </label>
                            {getUniqueValues("Type").map((type, index) => (
                                <label key={index} style={{ margin: "0.1rem 0rem" }}>
                                    <input
                                        type="radio"
                                        value={type}
                                        checked={selectedType === type}
                                        onChange={handleTypeChange}
                                        style={{ marginRight: "0.2rem" }}
                                    />
                                    {type}
                                </label>
                            ))}
                        </div>
                    </div>
                    <div style={{ borderRadius: "0.5rem", marginBottom: "0.625rem", padding: "0.625rem", backgroundColor: "#f9f9f9" }}>
                        <label style={{ fontSize: "0.9rem", fontWeight: "600" }}>Category: </label>
                        <div style={{ display: "flex", flexDirection: "column", margin: "0.3rem 0rem", paddingTop: "0.2rem" }}>
                            <label style={{ margin: "0.1rem 0rem" }}>
                                <input
                                    type="radio"
                                    value=""
                                    checked={selectedCategorization === ""}
                                    onChange={handleCategorizationChange}
                                    style={{ marginRight: "0.2rem" }}
                                />
                                All
                            </label>
                            {getUniqueValues("Category").map((categorization, index) => (
                                <label key={index} style={{ margin: "0.1rem 0rem" }}>
                                    <input
                                        type="radio"
                                        value={categorization}
                                        checked={selectedCategorization === categorization}
                                        onChange={handleCategorizationChange}
                                        style={{ marginRight: "0.2rem" }}
                                    />
                                    {categorization}
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
                <table id="data-table" className="custom-table" style={{ overflowY: 'auto', height: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ border: "0.125rem solid #87CEEB", cursor: "pointer" }} onClick={() => handleSort("Change Number")}>
                                {props.Header1} {getSortIcon("Change Number")}
                            </th>
                            <th style={{ border: "0.125rem solid #87CEEB", cursor: "pointer" }} onClick={() => handleSort("Configuration Item")}>
                                {props.Header2} {getSortIcon("Configuration Item")}
                            </th>
                            <th style={{ border: "0.125rem solid #87CEEB", cursor: "pointer" }} onClick={() => handleSort("Risk")}>
                                {props.Header3} {getSortIcon("Risk")}
                            </th>
                            <th style={{ border: "0.125rem solid #87CEEB", cursor: "pointer" }} onClick={() => handleSort("Short Description")}>
                                {props.Header4} {getSortIcon("Short Description")}
                            </th>
                            <th style={{ border: "0.125rem solid #87CEEB", cursor: "pointer" }} onClick={() => handleSort("Planned Start Date")}>
                                {props.Header5} {getSortIcon("Planned Start Date")}
                            </th>
                            <th style={{ border: "0.125rem solid #87CEEB", cursor: "pointer" }} onClick={() => handleSort("Planned End Date")}>
                                {props.Header6} {getSortIcon("Planned End Date")}
                            </th>
                            <th style={{ border: "0.125rem solid #87CEEB", cursor: "pointer" }} onClick={() => handleSort("State")}>
                                {props.Header7} {getSortIcon("State")}
                            </th>
                            <th style={{ border: "0.125rem solid #87CEEB", cursor: "pointer" }} onClick={() => handleSort("Type")}>
                                {props.Header8} {getSortIcon("Type")}
                            </th>
                            <th style={{ border: "0.125rem solid #87CEEB", cursor: "pointer" }} onClick={() => handleSort("Assignment Group")}>
                                {props.Header9} {getSortIcon("Assignment Group")}
                            </th>
                            <th style={{ border: "0.125rem solid #87CEEB", cursor: "pointer" }} onClick={() => handleSort("Requested By")}>
                                {props.Header10} {getSortIcon("Requested By")}
                            </th>
                            <th style={{ border: "0.125rem solid #87CEEB", cursor: "pointer" }} onClick={() => handleSort("Close Code")}>
                                {props.Header11} {getSortIcon("Close Code")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData?.length > 0 ? (
                            sortedData.map((value, key) => {
                                const differenceInHours = calculateDifferenceInHours(value["Planned Start Date"], value["Planned End Date"]);
                                const rowStyle = differenceInHours >= 48 ? { backgroundColor: '#EDEDED' } : {};
                    
                                return (
                                    <tr key={key} id={key} style={rowStyle}>
                                        <td style={{ border: "0.125rem solid #87CEEB" }}>
                                            {/^CHG/.test(value["Change Number"]) ? (
                                                <a 
                                                    href={`https://maersk.service-now.com/nav_to.do?uri=change_request.do?sysparm_query=number=${value["Change Number"]}`} 
                                                    target="_blank" 
                                                    rel="noopener noreferrer"
                                                    style={{ color: "#42b0d5", textDecoration: "none" }}
                                                >
                                                    {value["Change Number"]}
                                                </a>
                                            ) : (
                                                <a 
                                                    href={value["Short Description"]} 
                                                    target="_blank" 
                                                    rel="noopener noreferrer"
                                                    style={{ color: "#42b0d5", textDecoration: "none" }}
                                                >
                                                    {value["Change Number"]}
                                                </a>
                                            )}
                                        </td>
                                        <td style={{ border: "0.125rem solid #87CEEB" }}>
                                            <div>
                                                {value["Configuration Item"]}
                                                {value["Relation"] && (
                                                    <div style={{ fontSize: '0.75rem', color: '#666666' }}>
                                                        {value["Relation"]}
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td style={{ border: "0.125rem solid #87CEEB" }}>{value["Risk"]}</td>
                                        <td style={{ border: "0.125rem solid #87CEEB" }}>{value["Short Description"]}</td>
                                        <td style={{ border: "0.125rem solid #87CEEB" }}>
                                            {DateTime.fromFormat(value["Planned Start Date"], 'yyyy-MM-dd HH:mm:ss').toFormat('dd-MM-yyyy HH:mm:ss')}
                                        </td>
                                        <td style={{ border: "0.125rem solid #87CEEB" }}>
                                            {DateTime.fromFormat(value["Planned End Date"], 'yyyy-MM-dd HH:mm:ss').toFormat('dd-MM-yyyy HH:mm:ss')}
                                        </td>
                                        <td style={{ border: "0.125rem solid #87CEEB" }}>{value["State"]}</td>
                                        <td style={{ border: "0.125rem solid #87CEEB" }}>{value["Type"]}</td>
                                        <td style={{ border: "0.125rem solid #87CEEB" }}>{value["Assignment Group"]}</td>
                                        <td style={{ border: "0.125rem solid #87CEEB" }}>{value["Requested By"]}</td>
                                        <td style={{ border: "0.125rem solid #87CEEB" }}>
                                            <p style={{ color: getCloseCodeColor(value["Close Code"]) }}>{value["Close Code"]}</p>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="12" style={{ textAlign: "center", padding: "1rem", fontSize: "3rem", fontWeight: "600" }}>No Data Found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}